import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Device from "../components/media/Device";
import { Seo } from "../components/seo";

const Success = () => {
  return (
    <Layout>
      <StyledDiv>
        <h1>お問い合わせありがとうございます。</h1>
        <p>ご記入された情報は無事送信されました。</p>
        <p>
          2日以上たっても担当者より連絡がない場合、お手数ですがkuukiyo0312@gmail.comよりお問い合わせ下さい。
        </p>
        {/* <p></p> */}
        <Link to="/" className="link">
          トップページへ戻る
        </Link>
      </StyledDiv>
    </Layout>
  );
};

const StyledDiv = styled.div`
  padding: 25rem 5vw 0;
  text-align: center;
  h1 {
    font-size: 2.6rem;
    font-weight: bold;
    color: var(--sub-color);
    margin-bottom: 2rem;
    @media ${Device.tablet} {
      font-size: 4rem;
    }
  }
  p {
    font-size: 1.5rem;
    line-height: 3.2rem;
    @media ${Device.tablet} {
      font-size: 1.6rem;
    }
  }
  .link {
    display: block;
    padding: 1rem 0;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Success;

export const Head = () => <Seo title={"Success"} />;
